.at-tab__item {
  height: 50px;
  border: 1px solid #767676;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-right: 5px;
  background-color: #212443;
  border-bottom: none;
  color: white;
  padding: 0px 20px;
}

.at-tab__selected {
  height: 50px;
  border: 1px solid #5d30a1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #13142b;
  border-bottom: none;
  margin-right: 5px;
  color: white;
  font-weight: 600;
  padding: 0px 20px;
}