/* Top Section (Logo) */

.navbar {
    background-color: #191b28;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}


/* Navigation Bar Section */

.nav-menu {
    background-color: #191b28;
    width: 100px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    font-size: 20px;
    transition: width 500ms;
    z-index: 997;
}

.nav-menu-expand {
    width: 250px;
    background-color: #191b28;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    font-size: 20px;
    transition: width 500ms;
    z-index: 997;
}

.nav-menu-item {
    padding: 0%;
}

.nav-item-text {
    display: none;
    transition: 850ms;
}

.nav-item-text-expand {
    display: flex;
    margin: 0 30px;
    transition: 850ms;
}

.item-bars {
    margin: 5px 10px;
    display: flex;
    border-radius: 5px;
    overflow: hidden;
}

.logo-bars {
    padding-top: 10px;
}

.nav-text:hover {
    background-color: teal;
}

.nav-text-expand{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 30px;
}

.nav-text-collapse{
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-text {
    color: white;
    text-decoration: none;
    margin: 5px 0;
    width: 100%;
    height: 100%;
}

ul {
    padding: 0%;
    width: 100%;
}


/* Expand Button Section */

.nav-expand-btn {
    position: fixed;
    bottom: 15px;
    font-size: 25px;
}

.expand-btn {
    color: white;
    transform: rotateZ(0deg);
    transition: 850ms;
}

.collapse-btn {
    color: white;
    transform: rotateZ(-180deg);
    transition: 850ms;
}