.app{
  height: 100vh;
  background-size: cover;
  img {
    width: 50px;
    margin: 10px
  }
} 

.at-login__title {
  font-family: 'Stencil';
  width: 500px;
  margin: auto;
  font-size: 65px;
  text-align: center;
  color: #a72b1d;
}

.at-login__input{
  width: 300px;
  height: 50px;
  border-radius: 10px;
  background: #ffffffb0;
  border: 1px solid grey;
  font-size: 20px;
  padding-left: 20px;
}

.at-login__login-button {
  width: 150px;
  height: 50px;
  border-radius: 10px;
  border: none;
  color: white;
  background: #199FE5;
  font-weight: 500;
  font-size: 18px;
}

@media screen and ( max-width: 576px ){
  .at-login__title{
    font-size: 50px;
    width: calc( 100vw - 50px );
  }
}

@media screen and ( max-height: 616px ) {
  .app{
    height: 100%;
  }
  
}