.at-distributor-create__password-unmatch {
  color: white;
  font-size: 10px;
  margin-left: auto;
  margin-bottom: 0;
  width: fit-content;
  background-color: #b32121;
  padding: 5px 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.at-distributor-create__location-box {
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ced4da;
}