.main {
  margin-left: 100px;
  display: flex;
  justify-content: center;
}

.card {
  background: linear-gradient(75deg, #1a1c2e, #25284f);
  box-shadow: 1px 1px 3px #a88fbf;
  border-radius: 10px !important;
  border: 1px solid #b9b9b9;
  color: #fff;
  margin: 5px !important;
}

.title{
  margin: 0;
  color: #15192f;
  font-weight: 900;
  font-size: 40px;
  text-transform: uppercase;
  text-shadow: 1px 0px #a6b0f1, 0px 1px #a6b0f1, -1px 0px #a6b0f1, 0px -1px #a6b0f1, 0px 0px 14px #a6b0f1;
}

.row > * {
  padding: 0;
}

.table {
  --bs-table-bg: #0a0b1c !important;
  --bs-table-striped-color: #fff !important;
  --bs-table-striped-bg: rgb(30 32 61 / 45%) !important;
  --bs-table-hover-color: #fff !important;
  --bs-table-accent-bg: rgb(42 46 110 / 30%) !important;
  --bs-table-hover-bg: rgba(11 12 26 / 30%) !important;
  color: #fff !important;
  margin-bottom: 0 !important;
  border: 1px solid #fff;
  border-collapse: collapse;
  border-radius: 15px;
  overflow: hidden;
  border-color: #5d30a1 !important;
}

.table th {
  text-align: center;
}

.table th input {
  margin-top: 10px;
}

.table-container {
  border: 1px solid #5d30a1;
  border-radius: 15px;
  margin-bottom: 20px;
  overflow-x: auto;
}

.button-side-by-side {
  margin: 2px 2px;
}

.btn-dark {
  margin: 0 20px;
  border: 1px solid #5d30a1 !important;
}

.btn-dark:hover {
  border-color: #1c1f23;
  background-color: #b2becf !important;
  color: #1c1f23 !important;
}

.rbt-close-content{
  display: none
}
