.sales-container {
  display: flex;
  margin: 10px;
}

.small-container {
  margin: 10px;
}

.twoInrow {
  width: 30vw;
  height: 30vh;
}

.oneInrow {
  width: 30vw;
}

.card-title {
  font-size: 16px;
  font-weight: 900;
  text-transform: uppercase;
  color: #15192f;
  text-shadow: 1px 0px #a6b0f1, 0px 1px #a6b0f1, -1px 0px #a6b0f1,
    0px -1px #a6b0f1, 0px 0px 14px #a6b0f1;
}

.dashboard-button {
  background: linear-gradient(75deg, #1a1c2e, #25284f);
  box-shadow: 1px 1px 3px #b9b9b9;
  border-radius: 10px !important;
  border: 1px solid #a88fbf;
}

.dashboard-button-container{
  height: auto
}

.right-container {
  background: linear-gradient(75deg, rgb(13, 15, 42), rgb(33, 35, 80));
  height: 100%;
  width: 100%;
}

.list-group-items {
  background: rgb(19, 17, 28);
  padding: 15px 20px;
  color: #fff;
  margin-bottom: 5px;
  border-radius: 5px;
}

.list-group-item-action:hover {
  color: #fff;
  background-color: rgb(0, 0, 0);
}

.distributor-card-conatiner {
  height: calc(100vh - 350px);
  overflow-y: auto;
}

.distributor-container {
  padding: 30px;
  height: 100vh;
}

@media screen and (max-width: 992px) {
  .distributor-card-conatiner {
    height: 600px;
  }
  .distributor-container {
    margin-top: 20px;
    height: fit-content;
  }
}

@media (min-width: 768px) {
  .dashboard-button.card-title {
    font-size: 11px;
  }
  .dashboard-button-container{
    height: 50%
  }
  .visitor-counter {
    font-size: 10px;
  }
  .visitor-counter svg {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .dashboard-button.card-title {
    font-size: 14px;
  }
  .visitor-counter {
    font-size: 12px;
  }
  .visitor-counter svg {
    font-size: 20px;
  }
}

@media (min-width: 1100px) {
  .dashboard-button.card-title {
    font-size: 17px;
  }
  .visitor-counter {
    font-size: 12px;
  }
  .visitor-counter svg {
    font-size: 20px;
  }
}

@media (min-width: 1200px) {
  .dashboard-button.card-title {
    font-size: 14px;
  }
  .visitor-counter {
    font-size: 10px;
  }
  .visitor-counter svg {
    font-size: 16px;
  }
}

@media (min-width: 1700px) {
  .dashboard-button.card-title {
    font-size: 18px;
  }
  .visitor-counter {
    font-size: 14px;
  }
  .visitor-counter svg {
    font-size: 20px;
  }
}