.at-stock-create__image-display {
  width: 80px;
  object-fit: contain;
  margin-left: 5px;
  margin-bottom: 10px;
}

.at-stock-create__image-remove {
  color: #db3535;
  position: absolute;
  background-color: white;
  transform: translate(-5px, -10px);
  padding: 2px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.at-stock__category-filter {
  margin-left: 10px;
  .at-stock__select{
    color: black;
    max-width: 500px;
  }

  @media screen and ( max-width: 767px ) {
    margin-top: 20px;
  }
}
