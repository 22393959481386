.user-button-container {
  position: absolute;
  top: 10px;
  border: none;
  right: 10px;
  border-radius: 34px;
  background: #222545;
  padding: 6px;
  z-index: 1;
}

.user-button-container img {
  width: 50px;
  height: 50px;
}